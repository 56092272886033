<template>
    <div class="warning-container">这里是预警界面</div>
</template>

<script>
export default {

}
</script>

<style>
</style>